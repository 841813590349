(function() {
	'use strict';
	window.addEventListener('load', function() {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		var forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function(form) {
			form.addEventListener('submit', function(event) {
				if (grecaptcha && !grecaptcha.getResponse().length > 0) {
					console.log('Captcha not validated');
					var captchaElem = document.querySelector('.g-recaptcha');
					if (captchaElem) {
						captchaElem.classList.add('is-invalid');
					}
					event.preventDefault();
					event.stopPropagation();
				}
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			}, false);
		});
	}, false);
})();